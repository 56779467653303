<template>
  <button class="name" :data-clipboard-text="text" @click="copy">
    复制
  </button>
</template>

<script>
export default {
  name: "copyText",
  data() {
    return {
      text: '复制的内容'
    }
  },
  methods: {
    // 复制文本
    copy() {
      var clipboard = new this.Clipboard(".name");
      clipboard.on("success", () => {
        this.$toast("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 提示信息不支持复制
        this.$toast("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.name {
  background-color: transparent;
  border: none;
}

</style>